html {
    font-size: 14px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: #fff;
    color: #333;
    font-family: 'Open Sans', sans-serif;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
  }
  
  a {
    color: #1b73e8;
    text-decoration: none;
    font-weight: 600;
  }
  
  .logo-wrapper {
    text-align: center;
    margin: 4em 0 1em;
  }
  
  .content-body {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5f6368;
  }
  
  .heading,
  .sub-heading {
    font-weight: 600;
    color: #202124;
  }
  
  .text-center {
    text-align: center;
  }
  
  .btn {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    font-weight: 600;
    border-radius: 3px;
  }
  
  .btn.secondary {
    color: #1b73e8;
    background-color: #fff;
  }
  
  .btn.primary {
    color: #fff;
    background-color: #1b73e8;
  }
  
  .form-wrapper {
    width: 448px;
    min-height: 500px;
    margin: 0 auto;
    background-color: #fff;
    padding: 48px 40px 36px;
    border: 1px solid #dbdce0;
    border-radius: 5px;
  }
  
  .form-wrapper .logo img {
    width: 75px;
    display: block;
    margin: 0 auto;
  }
  
  .form-wrapper .heading {
    padding-top: 16px;
  }
  
  .form-wrapper .sub-heading {
    padding-top: 8px;
  }
  
  .form-wrapper form {
    padding: 24px 0 0 0;
  }
  
  .field {
    margin-top: 1rem;
    position: relative;
  }
  
  .input {
    width: 100%;
    padding: 13px 15px;
    border-radius: 3px;
    line-height: 24px;
    border: #dbdce0 solid 1px;
    font-size: 16px;
    position: relative;
  }
  
  .input:focus {
    box-shadow: 0 0 0 2px #1b73e8 inset;
    outline: none;
    transition: 100ms;
  }
  
  .label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 0%;
    transition: transform 100ms, left 100ms;
    font-size: 16px;
    pointer-events: none;
  }
  
  .field:focus-within .label,
  .input:not(:placeholder-shown) + .label {
    transform: scale(0.8) translateY(-43px);
    left: 12px;
    background-color: #fff;
    color: #1b73e8;
    padding: 1px 5px;
  }
  
  .description-text {
    margin-top: 32px;
    padding: 9px 0 3px;
  }
  
  .form-action {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }
  
  .form-action .btn.secondary {
    padding-left: 0;
  }
  
  /* For Mobile View */
  @media (max-width: 520px) {
    .form-wrapper {
      border: none;
      padding: 48px 20px 36px;
    }
  }