.blog-section {
    position: relative;
    top: 5rem;
    text-align: left;
    color: var(--text-color); /* Use CSS variable for text color */
    font-family: 'Arial', sans-serif;
  }
  
  .blog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 24px;
  }
  
  .blog-post {
    margin-bottom: 32px;
  }
  
  .blog-meta {
    display: flex;
    flex-direction: column;
  }
  
  .blog-category {
    font-weight: bold;
    color: var(--category-color); /* Use CSS variable for category color */
  }
  
  .blog-category-body {
    font-style: italic;
    color: var(--category-color); /* Use CSS variable for category color */
  }
  
  .blog-date {
    margin-top: 4px;
    font-size: 14px;
    color: var(--date-color); /* Use CSS variable for date color */
  }
  
  .blog-content {
    margin-top: 16px;
  }
  
  .blog-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--title-color); /* Use CSS variable for title color */
    margin-bottom: 16px;
  }
  
  .blog-description {
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-color); /* Use CSS variable for text color */
  }
  
  .blog-learn-more {
    display: inline-flex;
    align-items: center;
    color: var(--link-color); /* Use CSS variable for link color */
    text-decoration: none;
    cursor: pointer;
  }
  
  .blog-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
  