@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --header-height: 3rem;

  --hue-color: 250;

  --first-color: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 61%);
  --first-color-alt: hsl(var(--hue-color), 57%, 53%);
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 96%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);

  --body-font: "Poppins", sans-serif;
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  --font-medium: 500;
  --font-semi-bold: 600;

  --margin-bottom-0-25: 0.25rem;
  --margin-bottom-0-5: 0.5rem;
  --margin-bottom-0-75: 0.75rem;
  --margin-bottom-0-1: 1rem;
  --margin-bottom-0-1-5: 1.5rem;
  --margin-bottom-0-2: 2rem;
  --margin-bottom-0-2-5: 2.5rem;
  --margin-bottom-0-3: 3rem;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-model: 100;

  @media screen and (min-width: 968px) {
    :root {
      --big-font-size: 3rem;
      --h1-font-size: 2.25rem;
      --h2-font-size: 1.5rem;
      --h3-font-size: 1.25rem;
      --normal-font-size: 1rem;
      --small-font-size: 0.873rem;
      --smaller-font-size: 0.813rem;
    }
  }
}

/* dark color scheme  */
body.dark-theme {
  --first-color-second: hsl(var(--hue-color), 30%, 8%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 12%);
  --container-color: hsl(var(--hue-color), 29%, 16%);
  transition: 0.3s ease-in;
}

.nav-btns {
  display: flex;
  align-items: center;
}

/* theme  */
.change-theme {
  font-size: 1.25rem;
  color: var(--title-color);
  margin-right: 1rem;
  cursor: pointer;
}

.change-theme:hover {
  color: var(--first-color);
}

/* theme transition  */
.header,
.footer-bg {
  transition: 0.3s ease-in;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  transition: 0.3s ease-in;
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* resuable css class  */
.section {
  padding: 2rem 0 4rem;
}

.section-title {
  font-size: var(--h1-font-size);
}

.section-subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--margin-bottom-0-3);
}

.section-title,
.section-subtitle {
  text-align: center;
}

/* layout  */
.container {
  max-width: 768px;
  margin-left: var(--margin-bottom-0-1-5);
  margin-right: var(--margin-bottom-0-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo,
.nav-toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav-logo:hover {
  color: var(--first-color);
}

.nav-toggle {
  font-size: 1.1rem;
  cursor: pointer;
}

.nav-toggle:hover {
  color: var(--first-color);
}

@media screen and (max-width: 767px) {
  .nav-menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
}

.nav-list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav-link:hover {
  color: var(--first-color);
}

.nav-icon {
  font-size: 1.2rem;
}

.nav-close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--first-color);
}
.nav-close:hover {
  color: var(--first-color-alt);
}

.show-menu {
  bottom: 0;
}

.active-link {
  color: var(--first-color);
}

.home-container {
  gap: 1rem;
}

.home-content {
  grid-template-columns: 0.5fr 3fr;
  padding-top: 3.5rem;
  align-items: center;
}

.home-social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home-social-icon {
  font-size: 1.25rem;
  color: var(--first-color);
}

.home-social-icon:hover {
  color: var(--first-color-alt);
}

.home-blob {
  width: 200px;
  fill: var(--first-color);
}

.home-blob-img {
  width: 240px;
}

.home-data {
  grid-column: 1/3;
}

.home-title {
  font-size: var(--big-font-size);
}

.home-subtitle {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--margin-bottom-0-75);
}

.home-description {
  margin-bottom: var(--margin-bottom-0-2);
}

.home-scroll {
  /* display: none; */
}

.home-scroll-button {
  color: var(--first-color);
  transition: 0.3s;
}

.home-scroll-button:hover {
  transform: translateY(0.25rem);
}

.home-scroll-mouse {
  margin-right: 0.125rem;
  width: 24px;
}

.home-scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--margin-bottom-0-25);
}

.home-scroll-arrow {
  font-size: 1rem;
}

/* buttons  */
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
}
.button:hover {
  background-color: var(--first-color-alt);
}

.button-icon {
  font-size: 1.25rem;
  margin-left: var(--margin-bottom-0-5);
  transition: 0.3s;
}

.button-flex {
  display: inline-flex;
  align-items: center;
}

.button-small {
  padding: 0.75rem 1rem;
}

.button-white {
  background-color: #fff;
  color: var(--first-color);
}
.button-white:hover {
  background-color: #f1f1f1;
}
.button-link {
  padding: 0;
  background-color: transparent;
  color: var(--first-color-alt);
}

.button-link:hover {
  background-color: transparent;
  color: #5b0888;
}

/* about  */

.about-img {
  width: 200px;
  border: 0.5rem;
  justify-self: center;
  align-items: center;
  filter: drop-shadow(1px 1px 1px var(--first-color-lighter));
  transition: 0.3s;
}

.about-img:hover {
  filter: drop-shadow(2px 2px 2px var(--first-color-lighter));
}

.about-description {
  text-align: center;
  margin-bottom: var(--margin-bottom-0-2-5);
}

.about-info {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--margin-bottom-0-2-5);
}

.about-info-title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  color: #713abe;
}

.about-info-name {
  font-size: var(--smaller-font-size);
}

.about-info-title,
.about-info-name {
  display: block;
  text-align: center;
}

.about-buttons {
  display: flex;
  justify-content: center;
}

/* skills  */
.skills-content {
  display: inline-flex;
}

.skills-name {
  padding: 0.8rem 1.25rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  font-size: var(--small-font-size);
  background: var(--first-color-lighter);
  border-radius: 5px;
  font-weight: 600;
  color: var(--title-color);
  transition: 0.3s ease;
}

.skills-name:hover {
  background-color: var(--first-color-alt);
}
.skills-container {
  grid-template-columns: repeat(2, 1fr);
}
/* qualifi  */

.qualification-tabs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--margin-bottom-0-2);
}

.qualification-button {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  cursor: pointer;
  color: hsl(var(--hue-color), 8%, 45%);
}

.qualification-button:hover {
  color: var(--first-color);
}

.qualification-icon {
  font-size: 1.25rem;
  margin-right: var(--margin-bottom-0-25);
}

.qualification-data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.qualification-title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.qualification-subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--margin-bottom-0-1);
}

.qualification-date {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}

.qualification-round {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--first-color);
  border-radius: 50%;
}

.qualification-line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--first-color);
  transform: translate(6px, -7px);
}

.qualification [data-content] {
  display: none;
}

.qualification-active[data-content] {
  display: block;
}

.qualification-button.qualification-active {
  color: #5b0888;
}

/* services  */
.services-container {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

.services-content {
  position: relative;
  background-color: var(--container-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.services-icon {
  /* padding: 0 0 1rem 4.5rem; */
  height: 45%;
  width: 45%;
  display: center;
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: var(--margin-bottom-0-1);
}

.services-title {
  font-size: var(--h3-font-size);
  /* margin-bottom: var(--margin-bottom-0-1); */
  font-weight: var(--font-medium);
}

.services-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-model);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.services-box-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.services-box-services {
  row-gap: 1rem;
}

.services-box-list {
  display: flex;
}

.services-box-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--margin-bottom-0-1-5);
}
/* projects  */
.portfolio-container {
  overflow: initial;
}

.portfolio-content {
  padding: 0 1.5rem;
}

.portfolio-img {
  width: 265px;
  border-radius: 0.5rem;
  justify-self: center;
}

.portfolio-title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--margin-bottom-0-5);
}

.portfolio-description {
  margin-bottom: var(--margin-bottom-0-75);
}

.portfolio-button:hover .button-icon {
  transform: translateX(0.25rem);
}
/* projects  */

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
}

.swiper-icon {
  font-size: 1.25rem;
  color: var(--first-color);
}

.swiper-button-prev {
  left: -0.5rem;
}

.swiper-button-next {
  right: -0.5rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -2.5rem;
}

.swiper-angles {
  position: relative;
  bottom: 170px;
}

.project {
  text-align: center;
}

.project-bg {
  background-color: var(--first-color-second);
  padding-top: 3rem;
}

.project-title {
  font-size: var(--h2-font-size);
  margin-bottom: var(--margin-bottom-0-75);
}

.project-description {
  margin-bottom: var(--margin-bottom-0-1-5);
}

.project-title,
.project-description {
  color: #fff;
}

.project-img {
  width: 232px;
  justify-self: center;
}

/* testimonial  */
.testimonial-data,
.testimonial-header {
  display: flex;
}

.testimonial-data {
  justify-content: space-between;
  margin-bottom: var(--margin-bottom-0-1);
}

.testimonial-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: var(--margin-bottom-0-75);
}

.testimonial-name {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.testimonial-client {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.testimonial-description {
  margin-bottom: var(--margin-bottom-0-2-5);
}

.testimonial-icon {
  color: var(--first-color);
}

.swiper-container .swiper-pagination-testimonial {
  bottom: 0;
}

/* contact section */
.contact-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.contact-info {
    text-align: center;
}

.contact-icon {
    font-size: 1.5rem;
    color: var(--first-color);
    margin-bottom: var(--margin-bottom-0-75);
}

.contact-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.contact-subtitle {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}


/* footer   */
.footer {
  padding-top: 2rem;
}

.footer-container {
  row-gap: 3.5rem;
}

.footer-bg {
  background-color: var(--first-color-second);
  padding: 2rem 0 3rem;
}

.footer-title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--margin-bottom-0-25);
}

.footer-subtitle {
  font-size: var(--small-font-size);
}

.footer-links {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.footer-link {
  /* hii  */
}
.footer-link:hover {
  color: var(--first-color-lighter);
}

.footer-social {
  font-size: 1.25rem;
  margin-right: var(--margin-bottom-0-1-5);
}

.footer-social:hover {
  color: var(--first-color-lighter);
}

.footer-copy {
  font-size: var(--smaller-font-size);
  text-align: center;
  color: var(--text-color-light);
  margin-top: var(--margin-bottom-0-3);
}

.footer-title,
.footer-subtitle,
.footer-link,
.footer-social {
  color: #fff;
}

.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: var(--first-color);
  opacity: 0.8;
  padding: 0.2rem 0.4rem;
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup:hover {
  background-color: var(--first-color-alt);
}

.scroll-up-icon {
  font-size: 1.25rem;
  color: #fff;
}

.show-scroll {
  bottom: 5rem;
}

/* scroll thumb and bar  */
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;
}

/* media queries  */
/* small devices */
@media screen and (max-width: 350px) {
  .container {
    margin-left: var(--margin-bottom-0-1);
    margin-right: var(--margin-bottom-0-1);
  }

  .nav-menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav-list {
    column-gap: 0;
  }

  .home-content {
    grid-template-columns: 0.25fr 3fr;
  }

  .home-blob {
    width: 180px;
  }

  .skills-name {
    font-size: var(--small-font-size);
  }

  .qualification-data {
    gap: 0.5rem;
  }

  .services-container {
    grid-template-columns: max-content;
    justify-content: center;
  }

  .services-content {
    padding-right: 3.5rem;
  }

  .services-box {
    padding: 0 0.5rem;
  }

  .project-img {
    width: 200px;
  }

  .testimonial-data,
  .testimonial-header {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-img {
    margin-right: 0;
    margin-bottom: var(--margin-bottom-0-25);
  }

  .testimonial-data,
  .testimonial-description {
    text-align: center;
  }
}

/* medium devices */
@media screen and (min-width: 568px) {
  .home-content {
    grid-template-columns: max-content 1fr 1fr;
  }

  .home-data {
    grid-column: initial;
  }

  .home-blob-img {
    order: 1;
    justify-self: center;
  }

  .about-container,
  .skills-container,
  .portfolio-content,
  .project-container,
  .contact-container,
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .qualification-section {
    display: grid;
    grid-template-columns: 0.6fr;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  body {
    margin: 0;
  }

  .section {
    padding: 6rem 0 2rem;
  }

  .section-subtitle {
    margin-bottom: 4rem;
  }

  .header {
    top: 0;
    bottom: initial;
  }

  .header,
  .main,
  .footer-container {
    padding: 0 1rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 1rem;
  }

  .nav-icon,
  .nav-toggle,
  .nav-close {
    display: none !important;
  }

  .nav-list {
    display: flex;
    column-gap: 2rem;
  }

  .nav-menu {
    margin-left: auto;
  }

  .change-theme {
    margin: 0;
  }

  .home-content {
    padding-top: 5.5rem;
    column-gap: 2rem;
  }

  .home-blob {
    width: 270px;
  }
  .home-scroll {
    display: block;
  }
  .home-scroll-button {
    margin-left: 3rem;
  }

  .about-container {
    column-gap: 5rem;
  }

  .about-img {
    width: 350px;
  }

  .about-description {
    text-align: initial;
  }

  .about-info {
    justify-content: space-between;
  }

  .about-buttons {
    justify-content: initial;
  }

  .qualification-tabs {
    justify-content: center;
  }

  .qualification-button {
    margin: 0 var(--margin-bottom-0-1);
  }

  .qualification {
    grid-template-columns: 0.5fr;
  }

  .services-container {
    grid-template-columns: repeat(3, 218px);
    justify-content: center;
  }

  .services-icon {
    font-size: 2rem;
  }

  .services-content {
    padding: 6rem 0 2rem 2.5rem;
  }

  .services-box-content {
    width: 450px;
  }

  .portfolio-img {
    width: 320px;
  }

  .portfolio-content {
    align-items: center;
  }

  .project {
    text-align: inherit;
  }

  .project-bg {
    background: none;
  }

  .project-container {
    background-color: var(--first-color-second);
    border-radius: 1rem;
    padding: 3rem 2.5rem 0;
    grid-template-columns: 1fr max-content;
    column-gap: 3rem;
  }

  .project-data {
    padding-top: 0.8rem;
  }

  .footer-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer-bg {
    padding: 3rem 0 3.5rem;
  }

  .footer-links {
    flex-direction: row;
    column-gap: 2rem;
  }

  .footer-social {
    justify-self: flex-end;
  }

  .footer-copy {
    margin-top: 4.5rem;
  }
}

/* large devices */
@media screen and (min-width: 1024px) {
  .heder,
  .main,
  .footer-container {
    padding: 0;
  }

  .home-blob {
    width: 320px;
  }

  .home-social {
    transform: translateX(-6rem);
  }

  .services-container {
    grid-template-columns: repeat(3, 238px);
  }

  .portfolio-content {
    column-gap: 5rem;
  }

  .swiper-icon {
    font-size: 3.5rem;
  }

  .swiper-button-prev {
    left: -3.5rem;
  }

  .swiper-button-next {
    right: -3.5rem;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -4.5rem;
  }
}
